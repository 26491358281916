// Impact Text Animation
var impactHeading = $('.section--impact_homepage h1');
var impactDescription = $('.section--impact_homepage p');
var impactButton = $('.section--impact_homepage a.button');

function impactAnim(){
    $(impactHeading).html(wrapWordsInSpan($(impactHeading).text()));

    anime({
        targets: '.section--impact_homepage .inner',
        translateY: [100,0],
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1400,
        delay: (el, i) => 75 * i
    });

    anime({
        targets: '.section--impact_homepage p',
        opacity: {
            easing: 'linear',
            value: [0,1],
            duration: 100
        },
        translateY: [25,0],
        easing: "easeOutExpo",
        duration: 1400,
        delay: 300,
    });

    anime({
        targets: '.section--impact_homepage a.button',
        opacity: {
            easing: 'linear',
            value: [0,1],
        },
        translateY: [25,0],
        easing: "easeOutExpo",
        duration: 1400,
        delay: 500,
    });
}
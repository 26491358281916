const animateButton = (button) => {
    $(button).addClass('is-clicked');

    const buttonWidth = $(button).outerWidth();
    const buttonHeight = $(button).outerHeight();

    anime({
        targets: '.sector-detail-link.is-clicked span',
        easing: 'easeInOutCirc',
        duration: 100,
        opacity: [1, 0],
        translateY: [0, "5%"],
        delay: anime.stagger(100, {direction: 'reverse'}),
        begin: () => {
            $(button).css('width', buttonWidth + 'px');
            $(button).css('height', buttonHeight + 'px');
        },
        complete: () => {
            $('.sector-detail-link.is-clicked span').css("display", "none");
            anime({
                targets: '.sector-detail-link.is-clicked',
                easing: 'easeInOutCirc',
                duration: 250,
                width: [buttonWidth, "46px"],
            })
        }
    })
}
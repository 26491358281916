function updatePageLang(oldPage, newPage){
    const oldPageLang = $(oldPage).data('page-lang');
    const newPageLang = $(newPage).data('page-lang');

    if(oldPageLang !== undefined && newPageLang !== oldPageLang){
        showOldLangToggle(oldPageLang);
        $('html').attr('lang', newPageLang);
        return;
    } else {
        return false;
    }
}

function showOldLangToggle(oldPageLang) {
    $('.lang-toggle').removeClass('is-active');
    console.log(oldPageLang);
    $(`.lang-toggle[data-lang="${oldPageLang}"]`).addClass('is-active');
}
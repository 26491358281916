const subMenuWrapper = $('.sub-menu-wrapper');
const subMenu = $('.sub-menu');
const sectorSubMenu = $('.sector-sub-menu');

$('button[data-sub-menu-toggle], nav.sector-sub-menu a').on('click', function(){
    toggleSectorSubMenu();
});

const toggleSectorSubMenu = () => {
    if($(sectorSubMenu).hasClass('is-active')){
        disableSectorSubMenuLinks();
        $(sectorSubMenu).removeClass('is-active');
    } else {
        enableSectorSubMenuLinks();
        $(sectorSubMenu).addClass('is-active');
        trapFocus(sectorSubMenu);
    }
}

const enableSectorSubMenuLinks = () => {
    sectorSubMenu.removeAttr('aria-hidden');
    $(sectorSubMenu).find('a').removeAttr('tabIndex');
}

const disableSectorSubMenuLinks = () => {
    sectorSubMenu.attr('aria-hidden', 'true');
    $(sectorSubMenu).find('a').attr('tabIndex', '-1');
}

disableSectorSubMenuLinks();

const updateActiveSubMenuItem = (newPageContainer) => {
    const currentSectorButton = $('button.current-sector');
    const currentSectorPageLinks = $('.sub-menu nav.page-sub-nav');

    if($(newPageContainer).hasClass('page-template-template-sector')){
        const newPageTitle = $(newPageContainer).data('page-title');
        const newPageSectionLinks = $(newPageContainer).find('ul[data-page-section-links]').append('<span class="sub-nav-spacer"></span>');

        $(currentSectorPageLinks).html(newPageSectionLinks);
        anime({
            targets: '.sub-menu nav.page-sub-nav li',
            opacity: [0, 1],
            translateY: ["-10%", 0],
            delay: anime.stagger(50),
        })

        $(currentSectorButton).text(newPageTitle);
        $(currentSectorButton).removeAttr('tabindex');

        // Update Page Text
        $('nav.sector-sub-menu li').removeClass('current-menu-item');
        const newPageLink = $('nav.sector-sub-menu li a').filter(function(index) { return $(this).text() === newPageTitle; }).parents('li').addClass('current-menu-item');

        if(isScrollable($('ul.page-section-links'))){
            adjustSubNavSpacerWidth();
        } else {
            $('nav.page-sub-nav').removeClass('can-scroll');
        }
    } else {
        $(currentSectorButton).attr('tabindex', '-1');
    }
} 

const hideSubMenuWrapper = () => {
    $(subMenuWrapper).addClass('overflow-hidden');
    anime({
        targets: $(subMenu)[0],
        translateY: [0, '100%'],
        easing: 'spring(0.5, 95, 50, 0)',
    });
}

const showSubMenuWrapper = () => {
    anime({
        targets: $(subMenu)[0],
        translateY: ["-100%", 0],
        easing: 'spring(0.5, 95, 50, 0)',
        complete: () => {
            $(subMenuWrapper).removeClass('overflow-hidden');
        }
    });
}

function adjustSubNavSpacerWidth(){
    const spacer = $('.sub-nav-spacer');
    const browserWidth = $(window).outerWidth();
    const lastLinkWidth = $('nav.page-sub-nav a').last().width();
    const paddingOffset = 40;

    if($(lastLinkWidth).length > 0){
        $('ul.page-section-links').addClass('can-scroll');
        const spacerWidth = (browserWidth - lastLinkWidth - paddingOffset) / 2;
        $(spacer).width(spacerWidth);
    }
}

var resizeTimer;

$(window).on('resize', function(e) {

    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {

        if($('.sub-nav-wrapper').length > 0){
            $('.sub-nav-spacer').width(0);
            const canScrollSubNav = isScrollable($('ul.page-section-links'));

            if(canScrollSubNav){
                adjustSubNavSpacerWidth();
            } else {
                $('ul.page-section-links').removeClass('can-scroll');
            }
        }
            
    }, 250);

});

function throttle(fn, wait) {
    var time = Date.now();
    return function() {
      if ((time + wait - Date.now()) < 0) {
        fn();
        time = Date.now();
      }
    }
  }


// Adds classList function to jQuery
$.fn.classList = function() {return this[0].className.split(/\s+/);};

function isScrollable(div){
  return div.get(0).scrollWidth > div.get(0).clientWidth;
}

const trapFocus = (container) => {
  const tabbableItems = $(container).find('select, input, textarea, button, a').filter(':visible');

  const firstTabbable = $(tabbableItems).first();
  const lastTabbable = $(tabbableItems).last();

  lastTabbable.on('keydown', (e) => {
      if ((e.which === 9 && !e.shiftKey)) {
          e.preventDefault();
          firstTabbable.focus();
      }
  });

  firstTabbable.on('keydown', (e) => {
      if ((e.which === 9 && e.shiftKey)) {
          e.preventDefault();
          lastTabbable.focus();
      }
  });

  lastTabbable.on('keydown', (e) => {
      if ((e.which === 9 && !e.shiftKey)) {
          e.preventDefault();
          firstTabbable.focus();
      }
  });
}

function wrapWordsInSpan(str) {
    return str.replace(/\w+./g, "<span class='word'><span class='inner'>$&</span></span>");
}
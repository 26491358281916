const linesPositions = [];

function initLineAnimations(lines){
    lines.each((i, line) => {
        const lineTopPos = $(line).offset().top;
        linesPositions.push(lineTopPos);
    })
}

function animateLines(){
    const scrollPosition = $(window).scrollTop() + ($(window).innerHeight());

    $(linesPositions).each(function(i, linePosition){
        if(scrollPosition > linePosition){
            if($($('.line-highlight')[i]).hasClass('is-animated')){ 
                return true;
            }

            anime({
                targets: $('.line-highlight')[i],
                height: [0, '100%'],
                easing: 'easeInOutQuint',
                begin: () => {
                    $($('.line-highlight')[i]).addClass('is-animated');
                }
            });
        }
    });
}

const animateSectorLine = (sectorLine) => {
    anime({
        targets: $(sectorLine)[0],
        height: [0, '100%'],
        easing: 'easeInOutExpo',
        // delay: '100',
        duration: '2000',
    });
}
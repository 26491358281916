//-----------------------------------
// scripts.js
// Author: @codymarcoux (Studio123)
//
// NOTE: Main scripts file
//-----------------------------------


//-----------------------------------
// Mobile 100vh Fix
//-----------------------------------
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
let vhMenu = window.innerHeight * 0.01;

// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
document.documentElement.style.setProperty('--vhMenu', `${vhMenu}px`);

// Only want to resize the menu to prevent janky impact area
window.addEventListener('resize', () => {
    // We execute the same script as before
    let vhMenu = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vhMenu', `${vhMenu}px`);
    if ($(window).width() >= 769) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
});

barba.hooks.afterEnter((data) => {
    if ($(data.next.container).length === 1) {
        const previousPageContainer = $(data.current.container);
        const nextPageContainer = $(data.next.container);
        const pageClasses = $(nextPageContainer).classList();

        updatePageLang($(data.current.container), $(data.next.container));

        $('body').attr('class', '');
        pageClasses.forEach(function(value, index, array) {
            $('body').addClass(value)
        })

        if ($(nextPageContainer).hasClass('page-template-template-sector') === false) {
            if ($(previousPageContainer).hasClass('page-template-template-sector') !== false) {
                hideSubMenuWrapper();
            }
        }

        if ($(nextPageContainer).hasClass('page-template-template-sector')) {
            if ($(previousPageContainer).hasClass('page-template-template-sector') === false) {
                showSubMenuWrapper();
            }
            const sectorDownArrow = $(nextPageContainer).find('.section--sector_details').first().find('a');
            const sectorLine = $(nextPageContainer).find('.sector-line-highlight');
            setupSectorArrow($(sectorDownArrow));
            animateSectorLine($(sectorLine));
        }

        updateActiveSubMenuItem(nextPageContainer);
        initVideos();
        initSmoothScroll();
        initLoadMoreButtons();

        // Counter Up
        $('.counter').countUp();

        if ($(nextPageContainer).find('.section--impact_homepage').length > 0) {
            impactAnim();
        }

        if ($(nextPageContainer).find('.section--impact').length > 0) {
            initHeaderColorCheck($('.section--impact'));
        } else if ($(nextPageContainer).find('.section--impact_news').length > 0) {
            initHeaderColorCheck($('.section--impact_news'));
        } else if ($(nextPageContainer).find('.section--impact_homepage').length > 0) {
            initHeaderColorCheck($('.section--impact_homepage'));
        } else {
            initHeaderColorCheck();
        }

        const lines = $(nextPageContainer).find('.line-highlight');

        if (lines.length > 0) {
            initLineAnimations(lines);
            window.addEventListener('scroll', throttle(animateLines, 100));
        }
    }
});

barba.init({
    debug: false,
    prefetchIgnore: false,
    logLevel: 4,
    prevent: ({ el }) => el.classList && el.classList.contains('prevent-barba'),
    transitions: [{
            name: 'default-transition',
            sync: false,
            leave: function leave(data) {
                return new Promise(function(resolve) {
                    anime({
                        targets: data.current.container,
                        opacity: [1, 0],
                        easing: 'linear',
                        duration: 200,
                        begin: () => {},
                        complete: () => {
                            if ($(mainMenu).hasClass('is-active')) {
                                toggleMenu();
                            }
                            if ($('.sub-menu').hasClass('is-active')) {
                                $('.sub-menu').removeClass('is-active');
                            }
                            resolve();
                        }
                    });
                });
            },
            enter: function enter(data) {
                return new Promise(function(resolve) {
                    resolve();
                    anime({
                        targets: data.next.container,
                        easing: 'linear',
                        opacity: [0, 1],
                        delay: 50,
                        duration: 100,
                        begin: () => {
                            $(document).scrollTop(0);
                        },
                    });
                });
            }
        },
        {
            name: 'sector-block-transition',
            sync: false,
            from: {
                custom: ({
                    trigger
                }) => {
                    // Make sure it's a box element and not a navigation link
                    return trigger.classList && trigger.classList.contains('sector-detail-link');
                }
            },
            leave: function leave(data) {
                return new Promise(function(resolve) {
                    const currentSector = $(data.trigger).parents('section.section--sector_details');
                    const currentSectorPosition = $(currentSector).offset().top;
                    const currentSectorLine = $(currentSector).find('.line-highlight');

                    const animTimeline = anime.timeline();

                    // Scroll to section
                    animTimeline.add({
                        targets: 'html, body',
                        scrollTop: currentSectorPosition,
                        duration: 25,
                        easing: 'spring(1, 95, 50, 0)',
                        begin: () => {
                            headroom.unpin();
                            headroom.freeze();
                            animateButton(data.trigger);
                            anime({
                                targets: $(currentSectorLine)[0],
                                top: [0, '100%'],
                                easing: 'easeInOutQuint',
                            })
                        },
                        complete: () => {
                            const copyOfActiveSector = $(currentSector).clone();
                            $(copyOfActiveSector).addClass('is-fixed');
                            $('main').prepend(copyOfActiveSector);
                            $('body').css('top', -(document.documentElement.scrollTop) + 'px')
                                .addClass('no-scroll');
                        }
                    })

                    animTimeline.add({
                        targets: 'section:not(.is-active-sector)',
                        opacity: 0,
                        duration: 100,
                        delay: 50,
                        easing: 'linear',
                        begin: () => {
                            if ($(data.next.container).hasClass('page-template-template-sector') === false) {
                                hideSubMenuWrapper();
                            }
                            if ($(mainMenu).hasClass('is-active')) {
                                toggleMenu();
                            }
                        },
                        complete: () => {
                            resolve();
                        }
                    })
                });
            },
            enter: function enter(data) {
                return new Promise(function(resolve) {
                    const oldSector = $(data.current.container).find('.is-fixed');

                    if ($(oldSector).hasClass('img-left')) {
                        $(data.next.container).find('.section--sector_details').first().addClass('img-left');
                        $(data.next.container).find('.sector-line-highlight').first().addClass('img-left');
                    }

                    anime({
                        targets: 'section:not(.section--sector_details)',
                        easing: 'linear',
                        opacity: [0, 1],
                        translateY: ['5%', 0],
                        duration: 100,
                        begin: () => {
                            resolve();
                            const paddingTop = $('.sector-inner-wrapper').css('padding-top');
                            $('body').css('top', '0px').removeClass('no-scroll');
                            $(document).scrollTop(parseInt(paddingTop));
                        },
                        complete: () => {
                            setTimeout(() => {
                                $('body').removeClass('no-scroll');
                                headroom.unfreeze();
                            }, 1000)
                        }
                    });
                });
            }
        }
    ]
})

const setupSectorArrow = (button) => {
    $(button).on('click', function(event) {
        event.preventDefault();
        const parentHeight = $(this).parents('section').offset().top + $(this).parents('section').outerHeight();

        anime({
            targets: 'html, body',
            scrollTop: parentHeight,
            easing: 'spring(1, 95, 50, 0)',

        })
    })
}
var mainHeader = document.querySelector("header.main-header");

var options = {
    offset : 25,
    tolerance : {
        up : 10,
        down : 0
    },
}

var headroom  = new Headroom(mainHeader, options);
headroom.init();

function initHeaderColorCheck(impact){
    // Clear our scroll event 
    $(document).unbind("scroll");

    const isThereAnImpact = impact !== undefined;

    if(isThereAnImpact === false){
        $(mainHeader).addClass('past-impact');
        return;
    } else {
        $(mainHeader).removeClass('past-impact');
    }

    const impactHeight = $(impact).height();

    $(document).on('scroll', throttle(checkScrollPos, 25));
    
    function checkScrollPos(){
        const scrollPos = $(document).scrollTop();
        const mainHeader = $('header.main-header');

        if(scrollPos > impactHeight){
            if($(mainHeader).hasClass('past-impact')){
                // Do nothing
            } else {
                $(mainHeader).addClass('past-impact');
            }
        } else {
            if(mainHeader.hasClass('past-impact')){
                $(mainHeader).removeClass('past-impact');
            }
        }
    };
}

const mainMenu = $('.menu-container');
const mainMenuButton = $('.menu-container button[data-menu-close]');
const mainCloseAllMenu = $('.close-allmenu');
const mainMenuLinks = $('.menu-container a');
const mainSubMenuButton = $('.menu-item-has-children:not(.intervention) > a');
const menuCloseSubMenu = $('.menu-item-has-children .close-submenu');
const menuintervention = $('.intervention > a ');


$('button[data-menu-open], button[data-menu-close], .menu-overlay').on('click', function() {
    toggleMenu();
})

$(mainSubMenuButton).on('click', function(e) {
    e.preventDefault();
    $(this).next('.sub-menu').addClass('active');
    $('.menu-container').addClass('subnav-active');
    window.lastScrollTop = $('.menu-wrapper').scrollTop();
    $('.menu-wrapper').delay(500).animate({
        scrollTop: 0
    }, 0);
});

$(menuintervention).on('click', function(e) {
    e.preventDefault();
    $('.intervention.sub-menu').addClass('active');
    $('.menu-container').addClass('subnav-active');
    window.lastScrollTop = $('.menu-wrapper').scrollTop();
    $('.menu-wrapper').delay(500).animate({
        scrollTop: 0
    }, 0);
});

$(menuCloseSubMenu).on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();
    $('.menu-wrapper').animate({
        scrollTop: window.lastScrollTop
    }, 0);
    $('.menu-container').removeClass('subnav-active');
    $('.menu-item-has-children .sub-menu').removeClass('active');
});

$(document).on("keydown", (e) => escMenuPress(e));

$(mainCloseAllMenu).on('click', function(e) {
    e.preventDefault();
    disableMenuLinks();
    $(mainMenu).removeClass('is-active');
    $('html, body').removeClass('is-locked');
    $('button[data-menu-open]').focus();
    // close submenu
    $('.menu-wrapper').animate({
        scrollTop: window.lastScrollTop
    }, 0);
    $('.menu-container').removeClass('subnav-active');
    $('.menu-item-has-children .sub-menu').removeClass('active');
});

const toggleMenu = () => {
    if ($(mainMenu).hasClass('is-active')) {
        disableMenuLinks();
        $(mainMenu).removeClass('is-active');
        $('html, body').removeClass('is-locked');
        $('button[data-menu-open]').focus();
    } else {
        enableMenuLinks();
        trapFocus(mainMenu);
        $(mainMenu).addClass('is-active');
        $('html, body').addClass('is-locked');
        setTimeout(() => {
            $('button[data-menu-close]').focus();
        }, 250);
    }
}

const escMenuPress = (e) => {
    if (e.key === "Escape") {
        if (mainMenu.hasClass('is-active')) {
            toggleMenu();
        }
    }
}

const enableMenuLinks = () => {
    mainMenuButton.removeAttr('aria-hidden').removeAttr('tabIndex');
    mainMenu.removeAttr('aria-hidden');
    mainMenuLinks.removeAttr('tabIndex');
}

const disableMenuLinks = () => {
    mainMenuButton.attr('aria-hidden', 'true').attr('tabIndex', '-1');
    mainMenu.attr('aria-hidden', 'true');
    mainMenuLinks.attr('tabIndex', '-1');
}

disableMenuLinks();
function initLoadMoreButtons(){
    $('[data-load-more]').click(function(){
        var button = $(this);
        var loadMoreContainer = $(`.${button.data('load-container')}`);
        var maxPages = button.attr('data-max-page');
        var page = button.attr('data-page');
        var query = button.attr('data-posts');

        var data = {
            'action': 'load_more',
            'query': query,
            'page' : page 
        };

        $.ajax({ 
            url: '/wp-admin/admin-ajax.php',
            type : 'POST',
            data : data,
            beforeSend : function ( xhr ) {
                button.addClass('is-loading'); 
            },
            success : function( data ){
                if( data ) { 
                    var content = $(data);

                    // Append our posts
                    if(loadMoreContainer){
                        $(loadMoreContainer).append(content);
                    } else {
                        button.prev().before(content); 
                    }

                    button.removeClass('is-loading');
                    button.attr('data-page', parseInt(page) + 1);

                    if ( button.attr('data-page') == maxPages ){
                        button.remove();
                    }
                } else {
                    button.remove(); 
                }
            }
        });
    });
}